import { v4 as uuid } from 'uuid'
import Axios from '@/http'
// import store from '../store'

export default {
  getAwsAuth (file) {
    return new Promise((resolve) => {
      const uid = uuid()
      Axios.post('upload/getAwsAuth', { fileName: uid + file.name }).then(
        (res) => {
          resolve(res.data)
        }
      )
    })
  },
  upload (file) {
    return new Promise((resolve) => {
      this.getAwsAuth(file).then((item) => {
        const { uploadUrl } = item
        Axios.put(uploadUrl, file, {
          otherUrl: true,
          xAmzAcl: true
        }).then((res) => {
          const url = uploadUrl.split('?')[0]
          resolve(url)
        })
      })
    })
  }
}
