<!-- 设备型号 -->
<template>
  <div>
    <blockTitle title="设备型号"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <div class="h-8">
          <el-button-group>
            <el-button
                v-for="(item, index) in deviceTypeList"
                :key="index"
                size="mini"
                round
                class="select-none"
                :type="deviceType === item.deviceType ? 'primary' : ''"
                @click="changeDeviceType(item.deviceType)"
            >
              {{ item.deviceTypeName }}
            </el-button>
          </el-button-group>
        </div>
        <AppTable
            ref="AppTable"
            :customTableData="deviceModelList"
            :tableColumns="tableColumns"
        >
          <template #systemDeviceModel="{ row }">
            {{ row.factoryName }} / {{ row.brandName }} / {{ row.deviceModel }}
          </template>
          <template #customerDeviceModel="{ row }">
            {{ row.prodFactory }} / {{ row.prodBrand }} / {{ row.prodModel }}
          </template>
          <template #operator="{ row }">
            <el-button @click="toDetail(row)" size="mini"> 查看详情</el-button>
          </template>
        </AppTable>
      </div>
      <editDeviceDialog
          @on-submit="getData"
          ref="editDeviceDialog"
      ></editDeviceDialog>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'
import editDeviceDialog from 'components/device/editDeviceDialog'

export default {
  data () {
    return {
      deviceType: '',
      tableColumns: [
        {
          label: '原始型号',
          type: 'customSlot',
          slotName: 'systemDeviceModel'
        },
        {
          label: '展示型号',
          type: 'customSlot',
          slotName: 'customerDeviceModel'
        },
        {
          width: '120px',
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      deviceModelList: []
    }
  },
  components: {
    editDeviceDialog
  },
  computed: {
    ...mapGetters(['deviceTypeList'])
  },
  methods: {
    changeDeviceType (type) {
      this.deviceType = type
      this.getData()
    },
    getData () {
      this.$api.findAll('sysDevice', {
        deviceType: this.deviceType
      }).then(res => {
        this.deviceModelList = res.data
      })
    },
    addDevice () {
      this.$refs.editDeviceDialog.show(this.deviceType)
    },
    toDetail (item) {
      this.$router.push({
        name: 'deviceModelDetail',
        query: {
          deviceType: item.deviceType,
          deviceModel: item.deviceModel
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
